main {
  flex-grow: 1;
  /* Faz o conteúdo principal crescer para ocupar o espaço restante */
  display: flex;
  /* Usando flexbox para centralizar o conteúdo */
  align-items: center;
  /* Centraliza verticalmente */
  justify-content: center;
  /* Centraliza horizontalmente */
  text-align: center;
  /* Centraliza o texto */
  padding: 20px;
  /* Espaçamento interno */
}

.home-letreiro{
  font-size: 30px;
  color: #007bff;
}

.img-logo-home{
  width: 150px;
}

/* Estilos para o Componente Home */
.home-container {
  display: flex;
  /* Usando flexbox para layout */
  flex-direction: column;
  /* Itens dispostos em coluna */
  height: 100vh;
  /* Altura da tela cheia */
  background-color: #f0f0f0;
  /* Fundo suave */
  font-family: Arial, sans-serif;
  /* Fonte padrão */
}

header {
  background-color: #007bff;
  /* Cor do cabeçalho */
  color: white;
  /* Espaçamento interno do cabeçalho */
  padding: 10px;
}

.header-agendamento{
  background-color: #007bff;     /* Cor do cabeçalho */
  color: white;                   /* Cor do texto no cabeçalho */
  padding: 15px 20px;
  padding: 30px;  
  font-size: 28px           /* Espaçamento interno do cabeçalho */
}

nav ul {
  list-style: none;
  /* Remove marcadores da lista */
  display: flex;
  /* Disposição horizontal */
  justify-content: space-around;
  /* Espaçamento igual entre os itens */
  margin: 0;
  /* Remove margens */
  padding: 0;
  /* Remove padding */

  flex-direction: column;
  align-items: center;
}

nav ul li {
  font-size: 1.1em;
  /* Tamanho da fonte */
}

nav ul li a {
  color: white;
  /* Cor dos links */
  text-decoration: none;
  /* Remove sublinhado dos links */
  transition: color 0.3s;
  /* Transição suave para hover */
}

nav ul li a:hover {
  color: #ffcc00;
  /* Cor do link ao passar o mouse */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  max-width: 600px;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5em;
  /* Tamanho da fonte do título */
  margin: 0;
  /* Remove margens */
  margin-bottom: 20px;
  /* Margem inferior */
  color: #0004ff;
  font-size: 24px;
  transform: translateY(13px);
}



p {
  font-size: 1em;
  /* Tamanho da fonte do parágrafo */
  color: #666;
  /* Cor do texto */
  margin: 20px 0;
  transform: translateY(10px);
  /* Margem vertical */
}

.login-button {
  display: inline-block;
  /* Exibe como um bloco */
  padding: 10px 20px;
  /* Espaçamento interno */
  border: none;
  /* Sem borda */
  border-radius: 4px;
  /* Bordas arredondadas */
  background-color: #007bff;
  /* Cor do botão */
  color: white;
  /* Cor do texto do botão */
  text-decoration: none;
  /* Remove sublinhado */
  font-size: 1.1em;
  /* Tamanho da fonte do botão */
  transition: background-color 0.3s;
  /* Transição suave para hover */
  transform: translateY(30px);
}

.login-button:hover {
  background-color: #0056b3;
  /* Cor do botão ao passar o mouse */
}

footer {
  text-align: center;
  /* Centraliza o texto no rodapé */
  padding: 15px 0;
  /* Espaçamento interno */
  background-color: #007bff;
  /* Cor do fundo do rodapé */
  color: white;
  /* Cor do texto do rodapé */
}

footer p {
  margin: 0;
  /* Remove margens */
  font-size: 0.9em;
  /* Tamanho da fonte do rodapé */
}

/* Estilos responsivos */
@media (max-width: 600px) {
  h1 {
    font-size: 2em;
    /* Tamanho da fonte do título menor */
  }

  p {
    font-size: 1em;
    /* Tamanho da fonte do parágrafo menor */
  }

  .login-button {
    font-size: 0.9em;
    /* Tamanho da fonte do botão menor */
  }
  .login-container{
    padding: 30px;
  }


}

/* Estilos para o Componente Login */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centraliza verticalmente */
  height: 100vh; /* Altura da tela cheia */
  background-color: #f0f0f0; /* Fundo suave */
  font-family: Arial, sans-serif; /* Fonte padrão */
}



h2 {
  /* Tamanho da fonte do título */
margin-bottom: 20px;  
color: #0056b3;    
margin-left: 30px; /* Ajuste o espaço entre o ícone e o nome */
font-size: 24px; /* Tamanho da fonte */
font-weight: bold; 
transform: translateY(4px); 
}


form {
  background-color: white;
  /* Fundo branco para o formulário */
  border-radius: 8px;
  /* Bordas arredondadas */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Sombra suave */
  padding: 30px;
  /* Espaçamento interno */
  width: 100%;
  /* Largura total */
  max-width: 400px;
  /* Largura máxima do formulário */
}

input[type="email"],
input[type="password"] {
  width: 95%;
  /* Largura total dos campos */
  padding: 10px;
  /* Espaçamento interno */
  margin-bottom: 15px;
  /* Margem inferior entre campos */
  border: 1px solid #ccc;
  /* Borda leve */
  border-radius: 4px;
  /* Bordas arredondadas */
  font-size: 1em;
  /* Tamanho da fonte */
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #007bff;
  /* Cor da borda ao focar */
  outline: none;
  /* Remove contorno padrão */
}

button {
  width: 100%;
  /* Largura total do botão */
  padding: 10px;
  /* Espaçamento interno */
  background-color: #007bff;
  /* Cor do botão */
  color: white;
  /* Cor do texto do botão */
  border: none;
  /* Sem borda */
  border-radius: 4px;
  /* Bordas arredondadas */
  font-size: 1.1em;
  /* Tamanho da fonte do botão */
  cursor: pointer;
  /* Cursor de ponteiro ao passar o mouse */
  transition: background-color 0.3s;
  /* Transição suave para hover */
}

button:hover {
  background-color: #0056b3;
  /* Cor do botão ao passar o mouse */
}

button:disabled {
  background-color: #ccc;
  /* Cor do botão desativado */
  cursor: not-allowed;
  /* Cursor de não permitido */
}

.login.error {
  color: red;
  /* Cor do texto de erro */
  margin: 10px 0;
  /* Margens verticais */
}

/* Estilos responsivos */
@media (max-width: 600px) {
  h1 {
    font-size: 2em;
    /* Tamanho da fonte do título menor */
  }

  form {
    padding: 20px;
    /* Menos espaçamento interno em telas pequenas */
  }

  input[type="email"],
  input[type="password"] {
    font-size: 0.9em;
    
    /* Tamanho da fonte dos campos menor */
  }

  button {
    font-size: 0.9em;
    /* Tamanho da fonte do botão menor */
  }
}

/** ESTABELECIMENTO */
.estabelecimento-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.estabelecimento-title {
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

.estabelecimento-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
}

.estabelecimento-input {
  width: auto;
  max-width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.estabelecimento-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.estabelecimento-button:hover {
  background-color: #0056b3;
}


.estabelecimento-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  /* Para ocupar 100% do espaço disponível */
  max-width: 600px;
  /* Ou outro valor desejado */
  margin: 0 auto;
  /* Centraliza a lista */
}



.estabelecimento-item {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Mantém o alinhamento ao topo */
}

.estabelecimento-details {
  display: flex;
  flex-direction: column;
  /* Exibe os detalhes em coluna */
  margin-right: 20px;
  /* Espaço entre detalhes e botões */
}

body {
  font-family: Arial, sans-serif;
}

header {
  background-color: #007BFF;
  padding: 10px;
  position: relative;
  
}

.home-h1{
    margin-bottom: 20px;
    color: white;
    font-size: 24px;
    transform: translateY(13px);
}

.img-logo{
  width: 50px; 
  height: auto; 
  margin-left: 20px;
}



.ponto-icon{
  font-size: 28px;
  cursor: pointer;
  color: white;
  position: absolute;
  transform: translateY(3px); 
  left: 20px;
}

.dropdown-menu {
  list-style-type: none;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0px;
  top: 50px;
  z-index: 1;
  width: 150px;
  border-radius: 4px;
}

.dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu a {
  text-decoration: none;
  color: black;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #007BFF;
  color: white;
}

.dropdown-menu li:hover a {
  color: white;
}
.estabelecimento-details p {
  margin: 5px 0;
  /* Espaçamento entre os parágrafos */
}

.estabelecimento-button-group {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  /* Alinha os botões ao topo do item */
}

.estabelecimento-button-edit,
.estabelecimento-button-delete {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.estabelecimento-button-edit {
  background-color: #ffc107;
  color: white;
}

.estabelecimento-button-delete {
  background-color: #dc3545;
  color: white;
}

.estabelecimento-button-edit:hover {
  background-color: #e0a800;
}

.estabelecimento-button-delete:hover {
  background-color: #c82333;
}
nav {
  display: flex;
  align-items: center;
}

.logo-name {
  margin-left: 30px; /* Ajuste o espaço entre o ícone e o nome */
  font-size: 24px; /* Tamanho da fonte */
  font-weight: bold; /* Estilo da fonte */
  color: #002afb; /* Cor do texto (ajuste conforme sua paleta) */
}

.estabelecimento-loading,
.estabelecimento-error {
  font-size: 16px;
  color: red;
  margin-top: 10px;
}


/* Estilos para a página de Agendamento */
.agendamento-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;


  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.agendamento-title {
  margin-bottom: 20px;
  text-align: center;
  color: #007bff
}

.agendamento-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
}

.agendamento-input {
  width: auto;
  max-width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}


.agendamento-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.agendamento-button:hover {
  background-color: #0056b3;
}

.agendamento-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  /* Para ocupar 100% do espaço disponível */
  max-width: 600px;
  /* Ou outro valor desejado */
  margin: 0 auto;
  /* Centraliza a lista */
}



.agendamento-item {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  /* Mantém o alinhamento ao topo */
}

.agendamento-details {
  display: flex;
  flex-direction: column;
  /* Exibe os detalhes em coluna */
  margin-right: 20px;
  /* Espaço entre detalhes e botões */
}

.agendamento-details p {
  margin: 5px 0;
  /* Espaçamento entre os parágrafos */
}

.agendamento-button-group {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  /* Alinha os botões ao topo do item */
}

.agendamento-edit-button,
.agendamento-delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.agendamento-edit-button {
  background-color: #ffc107;
  color: white;
}

.agendamento-delete-button {
  background-color: #dc3545;
  color: white;
}


.agendamento-edit-button:hover {
  background-color: #e0a800;
}

.agendamento-delete-button:hover {
  background-color: #c82333;
}

@media (max-width: 600px) {
  .agendamento-container {
    padding: 10px;
  }

  .agendamento-form,
  .agendamento-list {
    max-width: 90%;
  }

  .agendamento-input {
    font-size: 0.9rem;
  }

  .agendamento-button,
  .agendamento-edit-button,
  .agendamento-delete-button {
    font-size: 0.9rem;
  }

  .agendamento-search {
    font-size: 14px;

  }
}

/* Estilos para a barra de pesquisa */
.cliente-search {
  width: 97%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #007bff;
  border-radius: 4px;
  font-size: 16px;
}

.suggestions {
  position: absolute;
  /* Faz com que a aba apareça sobre outros elementos */
  background-color: #fff;
  /* Fundo branco para a aba */
  border: 1px solid #ced4da;
  /* Borda para definir a área da aba */
  border-radius: 4px;
  /* Bordas arredondadas */
  z-index: 1000;
  /* Garantir que a aba apareça sobre outros elementos */
  max-height: 200px;
  /* Altura máxima da aba */
  overflow-y: auto;
  /* Permitir rolagem se houver muitas sugestões */

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Sombra para destacar */
}

.suggestions ul {
  list-style-type: none;
  /* Remover marcadores da lista */
  padding: 0;
  /* Remover preenchimento padrão */
  margin: 0;
  /* Remover margem padrão */
}

.suggestions li {
  padding: 10px;
  /* Espaçamento interno */
  cursor: pointer;
  /* Mostrar que é interativo */
}

.suggestions li:hover {
  background-color: #f8f9fa;
  /* Fundo ao passar o mouse */
}



.cliente-container {
  max-width: 600px;
  margin: 20px auto;
  /* Margem azul externa */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

/* Título */
.cliente-title {
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

/* Formulário */
.cliente-form {
  display: flex;
  flex-direction: column;
  max-width: 540px;
}

/* Inputs do Formulário */
.cliente-input {
  width: auto;
  /* Ou outra largura desejada */
  max-width: calc(100% - 20px);
  /* Para incluir o padding */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  /* Ajuste a borda conforme desejado */
  border-radius: 4px;
  font-size: 16px;
}


.cliente-input-email {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #007bff;
  /* Borda azul */
  border-radius: 4px;
  font-size: 16px;
}

/* Botão do Formulário */
.cliente-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.cliente-button:hover {
  background-color: #0056b3;
}

.cliente-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  /* Para ocupar 100% do espaço disponível */
  max-width: 600px;
  /* Ou outro valor desejado */
  margin: 0 auto;
  /* Centraliza a lista */
}



.cliente-item {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Mantém o alinhamento ao topo */
}

.cliente-details {
  display: flex;
  flex-direction: column;
  /* Exibe os detalhes em coluna */
  margin-right: 20px;
  /* Espaço entre detalhes e botões */
}

.cliente-details p {
  margin: 5px 0;
  /* Espaçamento entre os parágrafos */
}

.cliente-button-group {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  /* Alinha os botões ao topo do item */
}

.cliente-edit-button,
.cliente-delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cliente-edit-button {
  background-color: #ffc107;
  color: white;
}

.cliente-delete-button {
  background-color: #dc3545;
  color: white;
}

.cliente-edit-button:hover {
  background-color: #e0a800;
}

.cliente-delete-button:hover {
  background-color: #c82333;
}

/* Estilos de Loading e Erro */
.cliente-loading {
  text-align: center;
  font-size: 18px;
  color: #007bff;
}

.cliente-error {
  text-align: center;
  font-size: 18px;
  color: #dc3545;
}

/* Responsividade */
@media (max-width: 600px) {
  .cliente-container {
    padding: 15px;
  }

  .cliente-title {
    font-size: 24px;
  }

  .cliente-button {
    padding: 10px;
    font-size: 14px;
  }

  .cliente-input {
    font-size: 14px;
  }

  .cliente-search {
    font-size: 14px;
  }
}


/* Responsividade */
@media (max-width: 768px) {

  .estabelecimento-form,
  .cliente-form {
    max-width: 90%;
  }

  .agendamento-calendar {
    width: 100%;
  }
}

/* Estilos gerais */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Adicionando um estilo de carregamento para PWA */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  color: #4CAF50;
}